import './Main.css'
import corporate from '../logos/log-in.png'
import logobull from '../logos/new-logo-bull-web-01.png'

function Main(props){

    return(
        <div className="main">
            <header className="header">
                <div className='header-layer'>
                    <div className='banner'>
                        <img src={logobull} alt="logo-bull" className='logo-bull'/>
                        <ul>
                            <li>Inicio</li>
                            <li>Nosotros</li>
                            <li>Productos</li>
                            <li>Servicios</li>
                            <li>Partners</li>
                            <li><img src={corporate} alt="corporate" className='corporate'/></li>
                        </ul>

                    </div>
                    <div className='box-msg'>
                        <img src={logobull} alt="logo-bull" className='logo-2'/>
                        <h1>
                            
                            Pagina en construcción. Disculpe las molestias.</h1>
                    </div>
                    
                </div>
                
            </header>
        </div>

    )
}

export default Main;